import PubSub from "@/lib/Pub";
export default {
	name: "server1",
	data: () => {
		return {
			activeData: {
				title: "进修服务",
				data: [
					{
						img: require("@/assets/server1-1.png"),
						title: "进修目的",
						text:
							"通过在临床试验机构现场实地培训,统一培训教材,达到实训及适应本机构的正确导向式进修"
					},
					{
						img: require("@/assets/server1-2.png"),
						title: "进修申请",
						text:
							"由 SMO 公司申请成为 C&C Club 平台会员,为学员申请进修资格或个人注册会员申请进修"
					},
					{
						img: require("@/assets/server1-3.png"),
						title: "参与学员",
						text:
							"由 SMO 公司提供或个人申请的学员,经中国抗癌协会审评考核合格后,分配到合适的药物临床试验机构,由带教教师进行全方位进修带教"
					},
					{
						img: require("@/assets/server1-4.png"),
						title: "进修内容",
						text:
							"包括临床试验法律、法规和指导原则学习,GCP相关知识高级培训,临床试验操作技能现场实践,临床试验相关专业知识专家现场授课等"
					},
					{
						img: require("@/assets/server1-5.png"),
						title: "进修方式",
						text:
							"进修学员在C&C Club平台学习课程,参加线上线下考试,经最终考核通过后进入实地培训"
					},
					{
						img: require("@/assets/server1-6.png"),
						title: "资质认定",
						text:
							"经过培训的CRC将进行资质认证及专业等级考核,考核通过后颁发相应证书并登记备案"
					}
				]
			}
		};
	},
	methods: {
		jumpSetPage() {
			if (!this.$root.userInfo.name) {
				PubSub.publish("showLoginEvent");
			} else {
				if (this.$root.userInfo.type == 4) {
					this.$router.push({ name: "videoList" });
				} else {
					this.$message({
						showClose: true,
						message: "对不起，您无访问权限！",
						type: "warning"
					});
				}
			}
		}
	}
};
